import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Axios from "axios";

import GET_CURRENT_POST from "../../queries/CurrentPostQuery";
import GET_CURRENT_POST_EN from "../../queries/CurrentPostQueryEn";

import SEO from "../SEO";

import BlogpostContainerLeftColumn from "./BlogpostContainerLeftColumn";
import BlogpostPostContainer from "./BlogpostPostContainer";
import BlogpostCommentsContainer from "./BlogpostCommentsContainer";
import BlogpostCommentsQuantityContainer from "./BlogpostCommentsQuantityContainer";
import BlogpostAddCommentFormContainer from "./BlogpostAddCommentFormContainer";
import BlogpostCommentsWrapper from "./BlogpostCommentsWrapper";
import BlogpostCommentNameAndTextWrapper from "./BlogpostCommentNameAndTextWrapper";
import BlogpostCommentsAnswerContainer from "./BlogpostCommentsAnswerContainer";
import BlogpostAddCommentsAnswerFormContainer from "./BlogpostAddCommentsAnswerFormContainer";
import BlogpostCommentDateAndButtonWrapper from "./BlogpostCommentDateAndButtonWrapper";
import BlogpostContainerRightColumn from "./BlogpostContainerRightColumn";
import RecentPosts from "../RecentPosts/RecentPosts";
import PostsCategories from "../PostsCategories/PostsCategories";
import RecentComments from "../RecentComments";
import BackToTopButton from "../BackToTopButton/BackToTopButton";

import "./BlogPost.css";

import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useCurrentPostDataHook from "../../hooks/useCurrentPostDataHook";
import useLastFiveCommentsHook from "../../hooks/useLastFiveCommentsHook";
import useCurrentPostCommentsHook from "../../hooks/useCurrentPostCommentsHook";
import useCurrentPostCommentsQtyHook from "../../hooks/useCurrentPostCommentsQtyHook";

const BlogPost = () => {
  const { currentPostComments } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();
  const {
    getCategory,
    getCategoryEn,
    getPostTitle,
    getPostTitleEn,
    getCurrentPostID,
    getCurrentPostSlug,
    getCurrentPostSlugEn,
  } = useCurrentPostDataHook();
  const { getLastFiveComments } = useLastFiveCommentsHook();
  const { getCurrentPostComments } = useCurrentPostCommentsHook();
  const { getCurrentPostCommentsQty } = useCurrentPostCommentsQtyHook();
  let { slug } = useParams();

  const { error, loading, data } = useQuery(
    currentHref === atmaBankPl ? GET_CURRENT_POST : GET_CURRENT_POST_EN,
    {
      onCompleted: (data) => {
        Axios.post("/getComments")
          .then((res) => {
            //console.log("Wszystkie komentarze:");
            //console.log(res.data.comments);
            const currentComments = res.data.comments.filter((comment) => {
              return comment.postID === data.blogPosts[0].id;
            });
            let currentPost = data.blogPosts[0];
            console.log(currentPost);
            getCategory(currentPost.categories[0].name);
            getCategoryEn(currentPost.categories[0].name_en);
            getPostTitle(currentPost.title);
            getPostTitleEn(currentPost.title_en);
            getCurrentPostSlug(currentPost.slug);
            getCurrentPostSlugEn(currentPost.slug_en);
            getCurrentPostID(currentPost.id);
            getCurrentPostComments(currentComments);
            getCurrentPostCommentsQty(currentComments);
            getLastFiveComments(res.data.comments);
          })
          .catch((err) => {
            console.log("Nie udało się pobrać komentarzy");
          });
      },
      variables: { slug },
    }
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let currentPost = data.blogPosts[0];
  console.log(currentPost);
  console.log(currentHref);
  return (
    <div id="BlogPost">
      <SEO
        title={currentPost.title}
        titleEn={currentPost.title_en}
        description={currentPost.description}
        descriptionEn={currentPost.description_en}
        keywords={currentPost.keywords}
        keywordsEn={currentPost.keywords_en}
      />
      <div className="blogpost-container">
        <BlogpostContainerLeftColumn>
          <BlogpostPostContainer currentPost={currentPost} />
          <BlogpostCommentsContainer>
            <BlogpostCommentsQuantityContainer />
            <BlogpostCommentsWrapper>
              {currentPostComments.map((comment, index) => {
                return (
                  <div
                    id={`${
                      currentHref === atmaBankPl
                        ? currentPost.slug
                        : currentPost.slug_en
                    }-${index + 1}-comment`}
                    className="blogpost-comment-wrapper"
                    key={comment._id}
                  >
                    <BlogpostCommentNameAndTextWrapper comment={comment} />
                    <BlogpostCommentDateAndButtonWrapper comment={comment} />
                    <BlogpostAddCommentsAnswerFormContainer comment={comment} />
                    <BlogpostCommentsAnswerContainer
                      comment={comment}
                      parentCommentIndex={index}
                      currentPostSlug={currentPost.slug}
                      currentPostSlugEn={currentPost.slug_en}
                    />
                  </div>
                );
              })}
            </BlogpostCommentsWrapper>
            <BlogpostAddCommentFormContainer />
          </BlogpostCommentsContainer>
        </BlogpostContainerLeftColumn>
        <BlogpostContainerRightColumn>
          <RecentPosts />
          <PostsCategories />
          <RecentComments />
        </BlogpostContainerRightColumn>
        <BackToTopButton />
      </div>
    </div>
  );
};

export default BlogPost;
