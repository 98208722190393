import React from "react";
import { useNavigate } from "react-router-dom";

import DomainsState from "../../../domains/DomainsState";
import useResetPostsHook from "../../hooks/useResetPostsHook";
import useCurrentPostDataHook from "../../hooks/useCurrentPostDataHook";
import useResetFormHook from "../../hooks/useResetFormHook";
import useScrollToTopHook from "../../hooks/useScrollToTopHook";

const Navigation = () => {
  const navigate = useNavigate();
  const {
    komoraHiperbarycznaJeleniaGoraPl,
    atmaBankPl,
    atmaBankCom,
    currentHref,
  } = DomainsState();
  const { getResetPosts } = useResetPostsHook();
  const { getCategory, getCategoryEn } = useCurrentPostDataHook();
  const { resetForm } = useResetFormHook();
  const { scrollToTop } = useScrollToTopHook();

  return (
    <div id="Navigation">
      <div id="nav-container">
        <nav>
          <ul>
            <li
              onClick={() => {
                navigate("/");
                scrollToTop();
              }}
            >
              {currentHref === atmaBankPl ? "HOME" : "HOME"}
            </li>
            <li
              onClick={() => {
                navigate(currentHref === atmaBankPl ? "/o-mnie" : "/about");
                scrollToTop();
              }}
            >
              {currentHref === atmaBankPl ? "O MNIE" : "ABOUT"}
            </li>
            <li
              onClick={() => {
                navigate("/blog");
                getResetPosts();
                getCategory();
                getCategoryEn();
                scrollToTop();
              }}
            >
              {currentHref === atmaBankPl ? "BLOG" : "BLOG"}
            </li>
            <li
              onClick={() => {
                navigate(currentHref === atmaBankPl ? "/kontakt" : "/contact");
                resetForm();
                scrollToTop();
              }}
            >
              {currentHref === atmaBankPl ? "KONTAKT" : "CONTACT"}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
