import "./About.css";

const About = () => {
  return (
    <div id="About">
      <div className="about-container">
        <div className="about-image"></div>
        <div className="about-text-container">
          <div className="about-text-wrapper">
            <h3 className="about-title">Witaj</h3>
            <p className="about-text">
              Cieszę się, że zawitałeś na mojej stronie. Jest to blog poświęcony
              tematyce rozwoju duchowego. W szczególności tematyce wciąż
              uważanemu za tejemnicze zjawisko przebudzenia energii Kundalini.
              Jako, że z racji osobistych doświadczeń jest to temat szczególnie
              mi bliski pragnę podzielić się tutaj swoją wiedzą i
              spostrzeżeniami. Kto wie być może odnajdziesz tutaj informacje,
              które pomogą rozwiać choć częściowo Twoje wątpliwości, które
              bardzo często pojawiają się wraz z przebudzeniem tej niesamowitej
              mocy uśpionej w każdym z nas.
            </p>
            <p className="about-text">
              Stronę tę dedykuję wszystkim poszukiwaczom, którzy znajdują się na
              duchowej ścieżce bez względu na to czy są tego świadomi czy nie.
              Ostatecznie wszyscy się na niej znajdujemy, bo wszyscy poszukujemy
              na swój indywidualny sposób spokoju i szczęścia.
            </p>
            <p className="about-greetings-text">Pozdrawiam Cię</p>
            <p className="about-author-name">Radek Radhika Tomaszewski</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
