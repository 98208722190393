import React from "react";

import "./Home.css";

const Home = () => {
  return (
    <div id="Home">
      <div className="home-container">
        <div className="home-text-container">
          <h3 className="home-text-title">Atma Bank</h3>
          <h5 className="home-text-undertitle">
            Jedyne źródło zasobów jest w Tobie
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Home;
